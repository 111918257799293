/* hold all content except header and footer */
.mainContentContainer {
    display: flex;
    flex-direction: row;
    height: calc(100vh - 50px);
    color: var(--font-color);
    /* =13px */
    font-size: var(--main-size);
    position: relative;
  
}


/* page container  except sidebar*/
.pageContainer {
    min-width: 600px;
    height: inherit;
    flex: 1;
    background-color: var(--white-color);
    margin-top: 50px;
    overflow: auto;
    box-sizing: border-box;
}


/* hold all the content except page title */
.pageContentContainer {
    overflow-y: auto;
    margin: 20px 20px;
    padding: 0px 20px;
    overflow-x: auto;
    box-sizing: border-box;
    min-height: 1200px;
}

.pageContentContainerTwoColumn{
    display: flex;
    box-sizing: border-box;
    overflow-y: auto;
    margin: 20px 20px;
    padding: 0px 20px;
    overflow-x: auto;
   

}


.templatesContentWrapper {
    width: 100%;
}

.pageCardContentContainer {
    overflow-y: auto;
    overflow-x: auto;
}

.previewContentContainer {
    background-color: var(--light-background-color);
    overflow-y: auto;
    overflow-x: auto;
    margin: 32px;
    padding: 32px;

}


/* table container for short data display*/
.shortDataTable {
    width: 60%;
    min-width: 40%;
}

/* table container for short data display*/
.longDataTable {
    min-width: 80%;
}

/* table style can be used whole site*/
.pageTable {
    margin-top: 12px;
    width: 100%;
    border-collapse: collapse;
    border: 1px solid var(--border-color);
    border-radius: 8px;
}


.summaryTable {
    margin-top: 0px;
    width: 100%;
    border-collapse: collapse;
    background-color: var(--light-background-color);
}


.pageTable th {
    background-color: var(--light-background-color);
    height: 38px;

}

.pageTable th,
.pageTable tbody td {
    padding-left: 4px;
    text-align: left;
    vertical-align: middle;
    border-bottom: 1px solid var(--border-color);
}


.summaryTable tbody td {
    padding-left: 4px;
    text-align: left;
    vertical-align: middle;
}

.pageTable tr,
.summaryTable tr {
    height: 46px;
}
.pageTable tbody tr:hover,
.summaryTable tbody tr:hover {
    background-color: var(--table-hover);
}

.summaryTable tr .name:hover,
.pageTable tr .name:hover {
    text-decoration: underline;
    cursor: pointer;
    color: var(--main-color);
}


.summaryTable tbody td.tableFootName {
    padding-right: 10px;
    vertical-align: middle;
    text-align: right;
    font-weight: 600;
}

.summaryTable tbody tr.summaryTableTotalRow {
    border-top: 1px solid var(--border-color);
}




.summaryTable tbody td.summaryTableTotalName {
    font-size: 1rem;
    padding-right: 10px;
    vertical-align: middle;
    text-align: right;
    font-weight: 600;
    color: var(--main-color);
}

.summaryTable tbody td.summaryTableTotalValue {
    font-size: 1rem;
    /* padding-right: 10px; */
    text-align: left;
    color: var(--main-color);
}

/* tfoot style */
.pageTable tfoot td {
    padding-left: 4px;
    text-align: left;
    vertical-align: middle;
}

.pageTable tfoot td.tableFootName {
    padding-right: 10px;
    vertical-align: middle;
    text-align: right;
    font-weight: 600;
}


.taxText {
    font-size: var(--label-size);
    color: var(--gray-color);
}

.tableError {
    font-size: var(--label-size);
    margin: 4px 0px;
    border-radius: 2px;
    padding: 10px 0px 10px 3.2%;
    color: var(--delete-btn);
    background-color: var(--light-red-background-color);
    background-image: url("https://d1o1skvu5gf7wj.cloudfront.net/rui/web_icons/error_icon.svg");
    background-repeat: no-repeat;
    background-position: 1% center;
}

.textError {
    font-size: var(--label-size);
    margin: 4px 0px;
    padding: 10px 0px 10px 3.2%;
    color: var(--delete-btn);
    background-image: url("https://d1o1skvu5gf7wj.cloudfront.net/rui/web_icons/error_icon.svg");
    background-repeat: no-repeat;
    background-position: 0 center;
}

/* screen title */
.pageTitleContainer {
    height: 50px;
    background-color: var(--white-color);
    box-shadow: var(--bottom-box-shadow);
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 32px;
    box-sizing: border-box;

}
.pageTitle {
    /* margin: 10px 20px 10px 20px; */
    font-weight: 400;
}

/* css for blog content */
.blogContentCon {
    margin: 16px 0;

  }
  .blogContentCon p {
    line-height: 1.5;
  }

  .blogContentCon h2{
    margin: 20px 0;
    font-size: 1.5rem;
    font-weight: 500;
  }
  .blogContentCon h3{
    margin: 20px 0;
    font-size: 1rem;
    font-weight: 500;
  }
  
  .blogContentCon h3 + p{
    margin-left: 20px;
  }
  
  .blogContentCon p + p{
    margin-top: 20px;
  }
  
  .blogContentCon ul{
    margin: 20px 0;
    margin-left: 20px;
    list-style-type: disc;
  }
  
  .blogContentCon li{
    margin-left: 20px;
  }
  
  .blogContentCon img{
    display: block;
    width: 70%; /* Set the maximum width of the image to its container */
    height: auto;
    margin-left: auto;
    margin-right: auto;
    object-fit: contain;
  
  }
  
  .blogContentCon blockquote {
    border-left: 2px solid var(--light-gray-color); /* Set border color and width */
    padding-left: 20px; /* Add padding to create space between border and content */
    margin: 20px; /* Add margin for spacing around the blockquote */
    color: var(--light-gray-color);
  }
  
  .blogContentCon blockquote p {
    color: var(--gray-color);
  }
  
  .blogContentCon a {
    color: var(--main-color);
    text-decoration: underline;
  }
  
  /* table style can be used whole site*/
  .blogContentCon table {
    margin: 20px 0;
    width: 100%;
    border-collapse: collapse;
    border: 1px solid var(--border-color);
    border-radius: 8px;
  }
  
  
  
  .blogContentCon table th {
    background-color: var(--light-background-color);
    height: 38px;
  
  }

  .blogContentCon table td {
    line-height: 1.5;
  
  }
  
  .blogContentCon table th,
  .blogContentCon table tbody td {
    padding: 10px 4px;
    text-align: left;
    vertical-align: middle;
    border-bottom: 1px solid var(--border-color);
    border-left: 1px solid var(--border-color);
  }

  /* Popup window can be used whole site */
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--popup-overlay);
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
}

.inputModal {
    width: 50%;
    max-height: 70%;
    position: absolute;
    /* viewport 50% */
    left: 50%;
    top: 20%;
    margin-left: -25%;
    background-color: var(--white-color);
    border-radius: 4px;
    box-shadow: var(--box-shadow);
    border: none;
    overflow: auto;
}

.contentModal {
    width: 70%;
    max-height: 70%;
    position: absolute;
    /* viewport 50% */
    left: 50%;
    top: 20%;
    margin-left: -30%;
    background-color: var(--white-color);
    border-radius: 4px;
    box-shadow: var(--box-shadow);
    border: none;
    overflow: auto;
}

.fullScreenModal {
    width: 100%;
    max-height: 100%;
    background-color: var(--white-color);
    border-radius: 4px;
    box-shadow: var(--box-shadow);
    border: none;
    overflow: auto;
}




.modalContentContainer {
    width: 100%;
    margin: auto;
    padding-bottom: 10px;

}

.modalTitle {
    padding: 20px 20px;
    box-shadow: var(--box-shadow);
    display: flex;
    justify-content: space-between;
}



.modalContent {
    width: 50%;
    font-size: var(--main-size);
    align-items: center;
    justify-content: center;
    margin: 12px auto;
    
}

.modalContent_full {
    font-size: var(--main-size);
    align-items: center;
    justify-content: center;
    margin: 0 20px;
}


.closeIconContainer {
    display: flex;
    justify-content: flex-end;
    align-self: right;
    cursor: pointer;
    box-shadow: var(--box-shadow);
    /* background-color: var(--light-background-color); */
}

.closeIcon:hover {
    background-color: var(--border-color);
    box-shadow: var(--box-shadow-dark);

}

.closeIcon {
    width: 28px;
    height: 28px;
    color: var(--gray-color);
    background-color: var(--light-background-color);
}
/* simple dialog style css */
.dialogContentContainer {
    padding: 20px;
}
.dialogTitle {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
}
.dialogSuccessMessage {
    font-size: var(--main-size);
    padding: 20px 10px;
    color: var(--edit-btn);
    background-color:var(--light-green-background-color);
    border-radius: 2px;
}

.confirmBtnCon {
    display: flex;
}
.confirmBtn{
    margin: 12px 0 0 0;
    padding: 0 20px;
    height: 32px;
    border: 1px solid var(--main-color);
    display: flex;
    align-items: center;
    border-radius: 4px;
    font-size: var(--label-size);
    cursor: pointer;
}

.confirmBtn:hover {
    background-color: var(--main-color);
    color: var(--white-color);
}

/* error message style css */
.fadeErrorContainer{
    display: flex;
    align-items: center;
    padding: 20px 10px;
    background-color: var(--light-red-background-color);
    color: var(--delete-btn);
    border-radius: 2px;
    font-size: var(--main-size);
}

.fadeErrorContainer.hide{
    display: none;
}

.errorMesContainer {
    display: flex;
    align-items: center;
    padding: 20px 10px;
    background-color: var(--light-red-background-color);
    color: var(--delete-btn);
    border-radius: 2px;
    font-size: var(--main-size);
}

.loaderMessage,
.errorMessage {
    margin-left: 4px;
}

.errorMessageText {
    margin-top: 2px;
    color: var(--delete-btn);
}

/* css for single checkbox */

/* Hide the browser's default checkbox */
/* The container */

.checkboxContainer {
    display: block;
    position: relative;
    padding-left: 18px;
    cursor: pointer;
    font-size: var(--main-size);
    color: var(--font-color);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    line-height: 18px;
    transition: background-color 0.3s;
}

.checkboxContainer.labelSize {
    font-size: var(--main-size);
}

.checkboxContainer input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 16px;
    width: 16px;
    background-color: var(--border-color);
    border-radius: 2px;
}

/* On mouse-over, add a grey background color */
.checkboxContainer:hover input~.checkmark {
    background-color: var(--gray-color);
}

/* When the checkbox is checked, add a blue background */
.checkboxContainer input:checked~.checkmark {
    background-color: var(--main-color);

}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.checkboxContainer:hover input~.checkmark:after,
.checkboxContainer input:checked~.checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.checkboxContainer .checkmark:after {
    left: 5px;
    top: 2px;
    width: 4px;
    height: 8px;
    border: solid var(--white-color);
    border-radius: 1px;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

/* check box css end */

/* Pagination style */
.paginationCon {
    display: flex;
    height: 48px;
    align-items: center;
}

.pageItem {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
    height: 12px;
    width: 12px;
    background-color: var(--light-background-color);
    margin-right: 16px;
    border-radius: 2px;
    color: var(--gray-color);
    font-size: 14px;

}

.pageItemActive {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
    height: 12px;
    width: 12px;
    background-color: var(--main-color);
    margin-right: 16px;
    border-radius: 2px;
    color: var(--white-color);
    font-size: 14px;

}

.pageItem:hover {
    cursor: pointer;
}





