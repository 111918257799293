@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  /* font size  16px*/
  --main-size: 14px;
  /* font size  11px*/
  --label-size: 0.725rem;
  --tag-size: 0.6rem;
  /* the dark color for header and font color */
  --header-background-color: #2E2955;
  /* the darkest blue main color */
  --main-color: #2E2955;
  --main-hover: #2E2955;
  --light-blue: #C1D6FF;
  --slight-blue: #F4F7FF;
  --input-label: #757474;
  --border-color: #EFEFF0;
  --light-gray-bc: #EDF1F4;

  --white-color: #ffffff;
  --menu-hover-color: rgba(255, 255, 255, 0.6);
  
  --arrow-color: #C0C4CC;

  --light-hover-color: #DAE5FB;
  --table-row-color: #F4F8FF;
  --box-shadow: 6px 0px 18px rgba(0, 0, 0, 0.06);
  --bottom-box-shadow: 0 6px 8px -6px rgba(0, 0, 0, 0.06);
  --box-shadow-dark: 6px 0px 20px rgba(0, 0, 0, 0.15);
  --box-shadow-card: rgba(0, 0, 0, 0.08) 0px 4px 12px;
  --edit-btn: #51C013;
  --edit-btn-hover: #41AB05;
  --indicate-line: #FF8301;
  --delete-btn: #EC5251;
  --download-btn: #00A8BF;
  --hover-title: rgba(28, 28, 28, 0.7);
  --popup-overlay: rgba(28, 28, 28, 0.7);
  --gray-color: #8F8F8F;
  --default-label: #FFBC00;
  --table-hover: #F4F8FF;
  --light-gray-color: #f7f7ff;
    /* the very light blue color background*/
  --light-background-color: #F4F8FF;
  --light-red-background-color: #FFE8E8;
  --light-green-background-color: #EFFFE6;
  --light-blue-color: rgba(165, 207, 255, 0.3);
  --invoice-blue: #2288C9;
  --brown-color: #E58A20;
  --light-brown-bc: #FFECD7;
  --orange-color: #F64D13;
  --light-orange-bc: #FFECE6;
  --xero-blue: #1EC1E7;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}


h1{
  font-weight: 500;
  font-size: 2.2rem;

}

h2{
  font-weight: 500;
  font-size: 1.5rem;

}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

html {
  font-size: 100%
}

a {
  font-size: var(--main-size);
}
/* settings for placeholer text color */
::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--gray-color);
  opacity: 1;
  font-size: 0.825rem;
  /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: var(--gray-color);
  font-size: 0.825rem;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: var(--gray-color);
  font-size: 0.825rem;
}

/* Hide the scrollbar by default */
::-webkit-scrollbar {
  display: none;
}

/* Show the scrollbar when content overflows */
::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
}

/* Show the scrollbar on hover */
::-webkit-scrollbar:hover {
  display: block;
}

input.picker[type="date"] {
  position: relative;
}

input.picker[type="date"]::-webkit-calendar-picker-indicator {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  padding: 0;
  color: transparent;
  background: transparent;
}




